import { DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';

import Docxtemplater from 'docxtemplater';
import { groupBy, orderBy, round } from 'lodash';
import PizZip from 'pizzip';
import { BehaviorSubject, debounceTime } from 'rxjs';

import { AtlasService, BasePostCommandComponent } from 'pbc.angular';

import { format } from 'date-fns';
import { IAbteilung, IGutachtenResponseRow, IKunde, IMitarbeiterResponseRow, IPostRechnungRequest, IPostRechnungResponse, IProjekt, IRechnung, IStandort } from 'fa-kt.types';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { formatAddress, join } from 'pbc.functions';
import { ITourenSummaryBatchRequest } from 'pbc.types';
import { BesichtigungenSelectService } from '../../../../besichtigungen';
import { GutachtenService } from '../../../../gutachten';
import { ProjekteService } from '../../../../projekte';
import { PostRechnungCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-rechnung',
  templateUrl: './post-rechnung.component.html',
  styleUrls: ['./post-rechnung.component.css'],
})
export class PostRechnungCommandComponent extends BasePostCommandComponent<IPostRechnungRequest, IPostRechnungResponse> implements OnInit, OnDestroy {
  description: { context: string; command: string } = { context: 'FINANZEN', command: 'POST_RECHNUNG' };
  contentKey: keyof IPostRechnungRequest = 'rechnung';

  @Input() projekt: IProjekt | undefined = undefined;
  @Input() kunde: IKunde | undefined = undefined;
  @Input() abteilung: IAbteilung | undefined = undefined;
  @Input() gutachtenResponse: IGutachtenResponseRow[] = [];
  @Input() mitarbeiter: IMitarbeiterResponseRow[] = [];
  @Input() standorte: IStandort[] = [];
  @Input() set herunterladen(date: Date) {
    if (date) this.download();
  }
  $calculating = new BehaviorSubject<boolean>(false);

  $extraErklärung = new BehaviorSubject<string>('');
  $fahrtkostenErklärung = new BehaviorSubject<string>('');

  override patch(value?: Partial<IPostRechnungRequest>): void {
    super.patch(value);
    if (value) {
      this.calculateExtraVereinbarungenSumme(true);
      this.calculateFahrten(true);
    }
  }

  constructor(
    public postRechnung: PostRechnungCommandService,
    public besichtigungen: BesichtigungenSelectService,
    private projekte: ProjekteService,
    private gutachten: GutachtenService,
    private atlas: AtlasService,
  ) {
    super();
    this.form = this.fb.group({
      rechnung: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        kunde: [null, []],
        abteilung: [null, []],

        bezeichnung: [null, [Validators.required]],
        rechnungZaehler: [null, []],
        datum: [null, [Validators.required]],
        ansprechpartner: [null, [Validators.required]],
        plz: [null, [Validators.required]],
        ort: [null, [Validators.required]],
        addresse: [null, [Validators.required]],

        honorarVereinbarung: [null, []],
        honorarVereinbarungSumme: [null, []],

        gutachten: [null, []],
        stunden: [null, []],
        anschlaege: [null, []],
        seiten: [null, []],
        fotos: [null, []],
        extraVereinbarungs: [[], []],
        extraVereinbarungsSumme: [null, []],

        strecke: [null, []],
        fahrtzeit: [null, []],
        fahrtenSumme: [null, []],
        besichtigungen: [[], []],
        besichtigungenSumme: [null, []],
        touren: [null, []],

        auslagenSumme: [null, []],
        sonstigeKosten: [null, []],
        nachlass: [null, []],
        nettoSumme: [null, [Validators.required]],

        mwstSatz: [null, [Validators.required]],
        mwstSumme: [null, [Validators.required]],
        bruttoSumme: [null, [Validators.required]],

        auslagenOhneMwstSumme: [null, []],
        finaleSumme: [null, [Validators.required]],

        skonto: [null, []],
        zahlungsziel: [null, []],
        tageOhneSkonto: [null, []],
        skontoSumme: [null, []],
        nachSkontoSumme: [null, []],

        datei: [null, []],
      }),
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.form
      .get('rechnung.honorarVereinbarung')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateHonorarVereinbarungsSumme());
    this.form
      .get('rechnung.extraVereinbarungs')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.gutachten')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.stunden')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.anschlaege')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.seiten')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.fotos')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateExtraVereinbarungenSumme());
    this.form
      .get('rechnung.strecke')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateFahrten());
    this.form
      .get('rechnung.fahrtzeit')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateFahrten());
    this.form
      .get('rechnung.besichtigungen')
      ?.valueChanges.pipe(debounceTime(10), takeUntilDestroyed(this.destroyedRef))
      .subscribe(async (besichtigungen: string[]) => await this.calculateBesichtigungen(besichtigungen));
    this.form
      .get('rechnung.honorarVereinbarungSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.extraVereinbarungsSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.fahrtenSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.nachlass')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.auslagenSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.sonstigeKosten')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateNetto());
    this.form
      .get('rechnung.nettoSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateBrutto());
    this.form
      .get('rechnung.mwstSatz')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateBrutto());
    this.form
      .get('rechnung.bruttoSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateFinaleSumme());
    this.form
      .get('rechnung.auslagenOhneMwstSumme')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => this.calculateFinaleSumme());

    this.form.get('rechnung.nettoSumme')?.disable();
    this.form.get('rechnung.mwstSumme')?.disable();
    this.form.get('rechnung.bruttoSumme')?.disable();
    this.form.get('rechnung.finaleSumme')?.disable();
  }

  async prepare(): Promise<void> {
    await this.postRechnung.prepare();
  }

  request(payload: IPostRechnungRequest): Promise<IPostRechnungResponse | undefined> {
    return this.postRechnung.request(payload);
  }

  calculateHonorarVereinbarungsSumme(): void {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.value.rechnung;

    let honorarVereinbarungSumme = 0;
    if (rechnung.honorarVereinbarung) {
      const honorarVereinbarung = this.postRechnung.getRechnungHonorarVereinbarung(rechnung.honorarVereinbarung as string);
      if (this.projekt?.objektArt === 'spezial') {
        honorarVereinbarungSumme = Number(honorarVereinbarung?.['spezial']);
      }
      if (this.projekt?.objektArt === 'gewerbe' || !honorarVereinbarungSumme) {
        honorarVereinbarungSumme = Number(honorarVereinbarung?.['gewerbe']);
      }
      if (this.projekt?.objektArt === 'wohnen' || !honorarVereinbarungSumme) {
        honorarVereinbarungSumme = Number(honorarVereinbarung?.['wohnen']);
      }
    }
    this.form.get('rechnung')?.patchValue({ honorarVereinbarungSumme });
    this.$calculating.next(false);
  }

  calculateExtraVereinbarungenSumme(erklärungOnly = false): void {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const extraVereinbarungen = rechnung.extraVereinbarungs?.map((extraVereinbarung: string) => this.postRechnung.getRechnungExtraVereinbarungs(extraVereinbarung));
    let extraVereinbarungsSumme: number = 0;
    let erklärung = '';
    extraVereinbarungen?.forEach((ev) => {
      switch (ev?.['pro']) {
        case 'projekt':
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + Number(ev['summe']);
          erklärung = erklärung + 'EUR ' + ev['summe'] + ' für Projekt (' + ev?.label + ')\n';
          break;
        case 'gutachten':
          const gutachtenSumme = Number(ev['summe']) * Number(rechnung.gutachten);
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + gutachtenSumme;
          erklärung = erklärung + 'EUR ' + gutachtenSumme + ' für ' + (rechnung.gutachten || 0) + ' Gutachten (' + ev?.label + ')\n';
          break;
        case 'stunde':
          const stundenSumme = Number(ev['summe']) * Number(rechnung.stunden);
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + stundenSumme;
          erklärung = erklärung + 'EUR ' + stundenSumme + ' für ' + (rechnung.stunden || 0) + ' Stunden (' + ev?.label + ')\n';
          break;
        case 'seite':
          const seitenSumme = Number(ev['summe']) * Number(rechnung.seiten);
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + seitenSumme;
          erklärung = erklärung + 'EUR ' + seitenSumme + ' für ' + (rechnung.seiten || 0) + ' Seiten (' + ev?.label + ')\n';
          break;
        case 'anschlag':
          const anschlaegeSumme = Number(ev['summe']) * Number(rechnung.anschlaege);
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + anschlaegeSumme;
          erklärung = erklärung + 'EUR ' + anschlaegeSumme + ' für ' + (rechnung.anschlaege || 0) + ' Anschläge (' + ev?.label + ')\n';
          break;
        case 'foto':
          const fotosSumme = Number(ev['summe']) * Number(rechnung.fotos);
          extraVereinbarungsSumme = Number(extraVereinbarungsSumme) + fotosSumme;
          erklärung = erklärung + 'EUR ' + fotosSumme + ' für ' + (rechnung.fotos || 0) + ' Fotos (' + ev?.label + ')\n';
          break;
      }
    });
    if (!erklärungOnly) {
      extraVereinbarungsSumme = round(extraVereinbarungsSumme, 2);
      this.form.get('rechnung')?.patchValue({ extraVereinbarungsSumme });
    }
    this.$extraErklärung.next(erklärung);
    this.$calculating.next(false);
  }

  async calculateBesichtigungen(besichtigungen: string[]): Promise<void> {
    if (!besichtigungen) return;
    this.$calculating.next(true);
    const shapes = this.besichtigungen.selection$.getValue().filter(({ value }) => besichtigungen.includes(value));

    const request: ITourenSummaryBatchRequest = [];
    for (const [tour, besichtigungen] of Object.entries(groupBy(shapes, 'tour')).filter(([key, besichtigungen]) => key && besichtigungen.length > 0)) {
      const beginn = besichtigungen[0];
      const mitarbeiter = this.mitarbeiter.find((m) => m.mitarbeiter.id === beginn.mitarbeiter)?.mitarbeiter;
      let { addresse: start, addresse: ende } = this.standorte.find((st) => st.id === mitarbeiter?.standort);
      const row = { tour, label: 'am ' + format(new Date(beginn.datum), 'dd.MM.yyyy HH:mm'), beginn: formatAddress(start), besichtigungen: [] };
      for (const { label, value: id, datum, position: ende } of orderBy(besichtigungen, 'order')) {
        row.besichtigungen.push({ id, label: label.split(')').pop(), start, ende, datum: new Date(datum) });
        start = ende;
      }
      row.besichtigungen.push({ id: 'rueckweg', label: formatAddress(ende), start, ende, datum: moment(new Date(beginn.datum)).endOf('day').toDate() });
      request.push(row);
    }
    const touren = await this.atlas.getRouteSummariesInBatch(request);
    if (touren) this.form.get('rechnung')?.patchValue({ touren, fahrtzeit: round(touren.fahrtzeit, 2), strecke: round(touren.strecke, 2) });
    this.$calculating.next(false);
  }

  private calculateFahrten(erklaerungOnly = false) {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    let fahrtenSumme = 0;
    let erklärung = '';
    if (this.kunde && this.kunde.fahrtkostenAbrechnung !== 'keine') {
      switch (this.kunde.fahrtkostenAbrechnung) {
        case 'proStunde':
          fahrtenSumme = Number(rechnung.fahrtzeit) * Number(this.kunde?.fahrtkostenProStunde);
          erklärung = this.format(rechnung.fahrtzeit) + 'Std. * EUR ' + this.format(this.kunde?.fahrtkostenProStunde);
          break;
        case 'proKm':
          if (!this.kunde?.fahrtkostenAbRadius || Number(rechnung.strecke) >= (this.kunde.fahrtkostenAbRadius as number)) {
            fahrtenSumme = Number(rechnung.strecke) * Number(this.kunde?.fahrtkostenProKm);
            erklärung = this.format(rechnung.strecke) + 'km * ' + this.format(this.kunde?.fahrtkostenProKm) + ' EUR/km';
          }
          break;
      }
    }
    if (!erklaerungOnly) {
      fahrtenSumme = round(fahrtenSumme, 2);
      this.form.get('rechnung')?.patchValue({ fahrtenSumme });
    }
    this.$fahrtkostenErklärung.next(erklärung);
    this.$calculating.next(false);
  }

  calculateNetto() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    let nettoSumme =
      (Number(rechnung.honorarVereinbarungSumme) + Number(rechnung.extraVereinbarungsSumme) + Number(rechnung.fahrtenSumme) + Number(rechnung.sonstigeKosten) + Number(rechnung.auslagenSumme)) *
      (1 - Number(rechnung.nachlass) / 100);
    nettoSumme = round(nettoSumme, 2);
    this.form.get('rechnung')?.patchValue({ nettoSumme });
    this.$calculating.next(false);
  }

  calculateBrutto() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const mwstSumme = round(Number(rechnung.nettoSumme) * (Number(rechnung.mwstSatz) / 100), 2);
    let bruttoSumme = Number(rechnung.nettoSumme) + mwstSumme;
    bruttoSumme = round(bruttoSumme, 2);
    this.form.get('rechnung')?.patchValue({
      mwstSumme,
      bruttoSumme,
    });
    this.$calculating.next(false);
  }

  calculateFinaleSumme() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    let finaleSumme = Number(rechnung.bruttoSumme) + Number(rechnung.auslagenOhneMwstSumme);
    finaleSumme = round(finaleSumme, 2);
    this.form.get('rechnung')?.patchValue({ finaleSumme });
    this.$calculating.next(false);
  }

  format(number: number | undefined, digitsInfo = '0.2-2'): string {
    number = number || 0;
    const pipe = new DecimalPipe('de-DE');
    return pipe.transform(number, digitsInfo);
  }

  async download() {
    this.$loading.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;

    let kurz = '';
    let portfolio = '';
    if (Number(rechnung.extraVereinbarungsSumme) + Number(rechnung.fahrtenSumme) + Number(rechnung.auslagenSumme) + Number(rechnung.sonstigeKosten) + Number(rechnung.auslagenOhneMwstSumme) === 0)
      kurz = '_kurz';

    let wert = 0;
    let objekte = '';
    let objekt = '';
    let objektArt = '';
    let stichtag = '';
    let leistungsdatum = '';

    if (this.gutachtenResponse.length > 1) {
      portfolio = '_Portfolio';
      objekte = this.gutachtenResponse
        .map((row) => {
          wert = wert + Number(row.gutachten.marktwert);
          let gutachten = formatAddress(row.objekt.addresse);
          +': ' +
            this.gutachten.getGutachtenBewertungsAnlass(row.gutachten.bewertungsStatus)?.label +
            ', ' +
            this.gutachten
              .getGutachtenObjektArt(row.gutachten.objektArt as string)
              ?.label?.split(' | ')
              .pop();
          gutachten = gutachten + ' - ' + (row.gutachten?.stichtagMarktwert ? new Date(row.gutachten?.stichtagMarktwert as Date).toLocaleDateString() : '') + '';
          const abgabeDatum = new Date(row?.gutachten?.abgabeFinal || row?.gutachten?.stichtagMarktwert!);
          leistungsdatum = this.mapMonth(abgabeDatum.getMonth()) + ' ' + abgabeDatum.getFullYear();
          return gutachten;
        })
        .join('\n');
    } else if (this.gutachtenResponse.length === 1) {
      const row = this.gutachtenResponse.shift();
      wert = wert + Number(row?.gutachten.marktwert);
      objekt = formatAddress(row.objekt.addresse);
      objektArt = row?.gutachten.objektArt
        ? (this.gutachten
            .getGutachtenObjektArt(row?.gutachten.objektArt as string)
            ?.label?.split(' | ')
            .pop() as string)
        : '';
      stichtag = row?.gutachten?.stichtagMarktwert ? new Date(row?.gutachten?.stichtagMarktwert as Date).toLocaleDateString() : '';
      const abgabeDatum = new Date(row?.gutachten?.abgabeFinal || row?.gutachten?.stichtagMarktwert!);
      leistungsdatum = this.mapMonth(abgabeDatum.getMonth()) + ' ' + abgabeDatum.getFullYear();
    }

    let marktwert = '';
    if (wert > 0) marktwert = this.format(wert, '0.0-0');

    const [blob] = await Promise.all([this.files.get('fa-kt-apps/Rechnung' + portfolio + kurz + '.docx')]);
    const zip = new PizZip((await this.files.readFileAsync(blob as File)) as unknown as any);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      delimiters: { start: '{', end: '}' },
    });

    const partnerInnen = this.projekte.response$.getValue().projekte.find(({ id }) => id === rechnung.projekt)?.projekt.partnerInnen || [];

    const gezeichnet =
      join(
        partnerInnen
          .map((partner) => this.mitarbeiter.find(({ mitarbeiter: { id } }) => id === partner))
          .filter((p) => !!p?.mitarbeiter)
          .map((partner) => partner?.mitarbeiter.anzeigename + (partner?.mitarbeiter.id !== 'c281b9f5-cc7a-4230-99b4-134813818c4d' ? ' FRICS' : '')),
      ) || 'Dirk Fischer-Appelt FRICS';
    const obj = {
      gezeichnet,
      kundeName: this.kunde?.name || '',
      abteilungName: rechnung?.ansprechpartner || '',
      strasse: rechnung.addresse || '',
      plz: (rechnung.plz || '') + ' ' + (rechnung.ort || ''),
      datum: new Date().toLocaleDateString(),
      id: rechnung.bezeichnung,
      auftragsNr: this.projekt?.kundenZeichen || '',
      auftragsArt: this.projekte
        .getProjektProjektArt(this.projekt?.projektArt as string)
        ?.label?.split(' | ')
        .pop(),
      anlass: this.projekte
        .getGutachtenBewertungsAnlass(this.projekt?.bewertungsAnlass as string)
        ?.label?.split(' | ')
        .pop(),
      objekt,
      leistungsdatum,
      objektArt,
      stichtag,
      objekte,
      marktwert,
      honorar: this.format(rechnung.honorarVereinbarungSumme || 0),
      extra: this.format(rechnung.extraVereinbarungsSumme || 0),
      fahrten: this.format(rechnung.fahrtenSumme || 0),
      auslagen: this.format(rechnung.auslagenSumme || 0),
      sonstige: this.format(rechnung.sonstigeKosten || 0),
      netto: this.format(rechnung.nettoSumme || 0),
      mwstSatz: this.format(rechnung.mwstSatz || 0),
      mwstSumme: this.format(rechnung.mwstSumme || 0),
      brutto: this.format(rechnung.bruttoSumme || 0),
      auslagenOhne: this.format(rechnung.auslagenOhneMwstSumme || 0),
      final: this.format(rechnung.finaleSumme || 0),
      fahrtkostenErklärung: this.$fahrtkostenErklärung.getValue() ? 'Erklärung der Fahrtkosten: ' + this.$fahrtkostenErklärung.getValue() : '',
      extraErklärung: this.$extraErklärung.getValue() ? 'Erklärung der Extra-Vereinbarungen: ' + this.$extraErklärung.getValue() : '',
    };
    doc.render(obj);
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    this.files.downloadBlob(out, rechnung.bezeichnung + '.docx');
    this.$loading.next(false);
  }

  private mapMonth(month: number): string {
    switch (month) {
      case 0:
        return 'Januar';
      case 1:
        return 'Februar';
      case 2:
        return 'März';
      case 3:
        return 'April';
      case 4:
        return 'Mai';
      case 5:
        return 'Juni';
      case 6:
        return 'Juli';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'Oktober';
      case 10:
        return 'November';
      default:
        return 'Dezember';
    }
  }
}
