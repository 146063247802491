import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { orderBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, debounceTime, startWith, Subscription } from 'rxjs';

import { APP_TITLE, AuthService, Feld, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';
import { compileWithNunjucks } from 'pbc.functions';
import { ISelection } from 'pbc.types';

import {
  IAbschnitt,
  IBerichtsVorlage,
  IDeleteAbschnittRequest,
  IDeleteAbschnittResponse,
  IDeleteBerichtsVorlageRequest,
  IDeleteBerichtsVorlageResponse,
  IDeleteVorlageRequest,
  IDeleteVorlageResponse,
  IFormular,
  IPostAbschnittRequest,
  IPostAbschnittResponse,
  IPostBerichtsVorlageRequest,
  IPostBerichtsVorlageResponse,
  IPostSortiereAbschnitteRequest,
  IPostSortiereAbschnitteResponse,
  IPostVorlageKopierenRequest,
  IPostVorlageKopierenResponse,
  IPostVorlageRequest,
  IPostVorlageResponse,
} from 'fa-kt.types';

import { IVorlagePageResolverResult } from '.';
import { ErforderlichesDokumentsService, FormulareService, GeneratorService, GutachtenService, ProjekteService, ProjektService, StandortsService } from '../../..';
import { FelderService } from '../../../felder';
import { OrtKategoriesService, OrtsService } from '../../../geo';
import { KundeService } from '../../../kunden';
import {
  DeleteAbschnittCommandService,
  DeleteBerichtsVorlageCommandService,
  DeleteVorlageCommandService,
  PostAbschnittCommandService,
  PostBerichtsVorlageCommandService,
  PostSortiereAbschnitteCommandService,
  PostVorlageCommandService,
  PostVorlageKopierenCommandService,
} from '../../commands';
import { BerichtsVorlagenService, VorlagenService, VorlageService } from '../../querys';

@Component({
  selector: 'fa-kt-vorlage-page',
  templateUrl: './vorlage.page.html',
  styleUrls: ['./vorlage.page.css'],
})
export class VorlagePage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public id$ = new BehaviorSubject<string>('new');
  public creating$ = new BehaviorSubject<boolean>(false);

  public readonly $deleteVorlage: BehaviorSubject<IDeleteVorlageRequest | null> = new BehaviorSubject<IDeleteVorlageRequest | null>(null);
  public readonly $postVorlage: BehaviorSubject<IPostVorlageRequest | null> = new BehaviorSubject<IPostVorlageRequest | null>(null);
  public readonly $postVorlageKopieren: BehaviorSubject<IPostVorlageKopierenRequest | null> = new BehaviorSubject<IPostVorlageKopierenRequest | null>(null);
  public readonly $postAbschnitt: BehaviorSubject<IPostAbschnittRequest | null> = new BehaviorSubject<IPostAbschnittRequest | null>(null);
  public readonly $deleteAbschnitt: BehaviorSubject<IDeleteAbschnittRequest | null> = new BehaviorSubject<IDeleteAbschnittRequest | null>(null);
  public readonly $postSortiereAbschnitte: BehaviorSubject<IPostSortiereAbschnitteRequest | null> = new BehaviorSubject<IPostSortiereAbschnitteRequest | null>(null);
  public readonly $deleteBerichtsVorlage: BehaviorSubject<IDeleteBerichtsVorlageRequest | null> = new BehaviorSubject<IDeleteBerichtsVorlageRequest | null>(null);
  public readonly $postBerichtsVorlage: BehaviorSubject<IPostBerichtsVorlageRequest | null> = new BehaviorSubject<IPostBerichtsVorlageRequest | null>(null);

  public _vorlage: ISitemapPage;
  public _vorlagen: ISitemapPage;
  public _projekt: ISitemapPage;

  $_abschnitte = new BehaviorSubject<IAbschnitt[]>([]);
  $abschnitte = new BehaviorSubject<{ abschnitt: IAbschnitt; rendered: string; fehler?: boolean }[]>([]);
  $berichtsVorlagen = new BehaviorSubject<IBerichtsVorlage[]>([]);

  view$ = new BehaviorSubject<'preview' | 'abschnitt'>('preview');
  vorlageVisible = false;
  berichtsVorlageVisible = false;

  $formular = new BehaviorSubject<IFormular | undefined>(undefined);
  $formulare = new BehaviorSubject<IFormular[]>([]);

  $vorlagen = new BehaviorSubject<ISelection[]>([]);
  $alleFelder = new BehaviorSubject<Feld[]>([]);
  $projekte = new BehaviorSubject<ISelection[]>([]);
  $projekt = new BehaviorSubject<string | undefined>(undefined);
  $gutachtens = new BehaviorSubject<ISelection[]>([]);
  $gutachten = new BehaviorSubject<string | undefined>(undefined);
  $object = new BehaviorSubject<{ [key: string]: any }>({});
  $virtual = new BehaviorSubject<{ [key: string]: any }>({});
  errors: any[] = [];

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    public auth: AuthService,
    public files: FileService,
    public berichtsVorlagen: BerichtsVorlagenService,
    public felder: FelderService,
    public vorlage: VorlageService,
    public vorlagen: VorlagenService,
    public formulare: FormulareService,
    public deleteBerichtsVorlage: DeleteBerichtsVorlageCommandService,
    public postBerichtsVorlage: PostBerichtsVorlageCommandService,
    public deleteVorlage: DeleteVorlageCommandService,
    public postVorlage: PostVorlageCommandService,
    public postVorlageKopieren: PostVorlageKopierenCommandService,
    public generator: GeneratorService,
    public orts: OrtsService,
    public ortKategories: OrtKategoriesService,
    public standorte: StandortsService,
    public postAbschnitt: PostAbschnittCommandService,
    public deleteAbschnitt: DeleteAbschnittCommandService,
    public postSortiereAbschnitte: PostSortiereAbschnitteCommandService,
    public projekt: ProjektService,
    public gutachten: GutachtenService,
    public kunde: KundeService,
    public projekte: ProjekteService,
    public erforderlicheDokumente: ErforderlichesDokumentsService,
  ) {
    this.page = this.sitemap.TEXTE.Pages.VORLAGE;
    this._vorlage = sitemap.TEXTE.Pages.VORLAGE;
    this._vorlagen = sitemap.TEXTE.Pages.VORLAGEN;
    this._projekt = sitemap.PROJEKTE.Pages.PROJEKT;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {
          if (params.projekt) this.$gutachten.next(params.projekt);
          if (params.gutachten) this.$gutachten.next(params.gutachten);
          if (params.id) this.id$.next(params.id);
          else await this.navigateToVorlagen();
        }),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IVorlagePageResolverResult = data.querys;
          if (querys && querys.vorlage && querys.vorlage.vorlage) this.$postVorlage.next({ vorlage: querys.vorlage.vorlage });
        }),
        this.id$.subscribe(async (id) => {
          const creating = id === 'new';
          this.creating$.next(creating);
          if (!creating) await this.vorlage.request({ id });
          else this.vorlageVisible = true;
        }),
        this.vorlagen.response$.subscribe((vorlagen) => {
          if (!vorlagen?.vorlagen) return;
          this.$vorlagen.next(
            vorlagen.vorlagen.map(({ vorlage }) => ({
              value: vorlage.id,
              label: `${vorlage.initialen} | ${vorlage.name}`,
            })),
          );
        }),
        this.vorlage.result$.subscribe((result) => {
          this.page.title = 'Vorlage' + (result?.vorlage?.name ? ': "' + result?.vorlage?.name + '"' : '');
          this.titleRef.setTitle(this.page.title);
          if (result?.vorlage?.id) {
            this.$_abschnitte.next(orderBy(result.abschnitte, 'order'));
            this.$deleteVorlage.next({ id: result.vorlage.id });
            this.$postVorlageKopieren.next({
              vorlage: result.vorlage.id,
            });
            this.$postVorlage.next({ vorlage: result.vorlage });
          } else if (!result?.vorlage) {
            this.$_abschnitte.next([]);
            this.$deleteVorlage.next(undefined);
            this.$postVorlageKopieren.next(undefined);
          }
        }),
        combineLatest([this.vorlage.result$, this.formulare.response$]).subscribe(([vorlage, formulare]) => {
          if (!vorlage?.vorlage || !formulare?.formulare?.length) return;
          const { fuerBewertungsAnlaesse, fuerKunden, fuerKundenArten } = vorlage.vorlage;
          this.$formulare.next(
            formulare?.formulare
              .map(({ formular }) => formular)
              .filter(
                ({ bewertungsAnlaesse, kundenArten, kunden }) =>
                  (!fuerKunden?.length || !kunden?.length || kunden.some((id) => fuerKunden.includes(id))) &&
                  (!fuerBewertungsAnlaesse?.length || !bewertungsAnlaesse?.length || bewertungsAnlaesse.some((id) => fuerBewertungsAnlaesse.includes(id))) &&
                  (!fuerKundenArten?.length || !kundenArten?.length || kundenArten.some((id) => fuerKundenArten.includes(id))),
              ),
          );
        }),
        combineLatest([this.$_abschnitte, this.$object]).subscribe(([abschnitte, object]) => {
          if (!abschnitte?.length || !object) return;
          this.errors = [];
          this.$abschnitte.next(
            abschnitte.map((abschnitt) => {
              try {
                return { abschnitt, rendered: compileWithNunjucks(abschnitt.text, object, this.generator.nunjucks) };
              } catch (error: any) {
                console.error(error);
                this.errors.push(error);
                return { abschnitt, rendered: '#FEHLER', fehler: true };
              }
            }),
          );
        }),
        combineLatest([this.berichtsVorlagen.response$, this.vorlage.result$]).subscribe(([berichtsVorlagen, vorlage]) => {
          if (!vorlage?.vorlage || !berichtsVorlagen?.berichtsVorlagen) return;
          const { id } = vorlage.vorlage;
          this.$berichtsVorlagen.next(
            berichtsVorlagen?.berichtsVorlagen
              .filter(({ berichtsVorlage }) => !berichtsVorlage.vorlagen?.length || berichtsVorlage.vorlagen.includes(id))
              .map(({ berichtsVorlage }) => berichtsVorlage),
          );
        }),
        this.felder.response$.subscribe((felder) => {
          if (!felder?.felder) return;
          this.$alleFelder.next(
            felder.felder
              .filter(({ feld }) => feld)
              .map(({ feld, feldOptionen }) => {
                let icon;
                let tooltip;
                switch (feld.art) {
                  case 'datum':
                    icon = 'field-time';
                    tooltip = 'Datum';
                    break;
                  case 'haken':
                    icon = 'check-circle';
                    tooltip = 'Haken';
                    break;
                  case 'text':
                    icon = 'field-string';
                    tooltip = 'Text';
                    break;
                  case 'zahl':
                    icon = 'field-number';
                    tooltip = 'Zahl';
                    break;
                  case 'option':
                    icon = 'check-square';
                    tooltip = 'Option';
                    break;
                  case 'optionPlus':
                    icon = 'check-square';
                    tooltip = 'Option Plus';
                    break;
                  case 'mehrfachauswahlPlus':
                    icon = 'select';
                    tooltip = 'Mehrfachauswahl';
                    break;
                }
                return {
                  ...feld,
                  feldOptionen,
                  icon,
                  tooltip,
                  _search: ((feld._search || '') + (feldOptionen?.map(({ option }) => option).join('') || '')).toLowerCase(),
                };
              }),
          );
        }),
        combineLatest([this.$alleFelder, this.$formular.pipe(startWith(undefined))]).subscribe(([felder, formular]) => {
          const virtual = this.generator.setVirtual(felder.filter(({ id }) => !formular?.felder.length || formular?.felder.includes(id)));
          this.$virtual.next(virtual);
        }),
        combineLatest([this.projekte.response$, this.vorlage.result$, this.$formular]).subscribe(async ([projekte, vorlage, formular]) => {
          if (!projekte?.projekte || !vorlage?.vorlage) return;

          const { bewertungsAnlaesse = [], kunden = [], kundenArten = [] } = formular || {};
          const { fuerBewertungsAnlaesse, fuerKunden, fuerKundenArten, fuerKundenGruppen, fuerObjektArten } = vorlage.vorlage;
          this.$projekte.next(
            projekte.projekte
              .filter(
                ({ projekt, kunde, gutachten }) =>
                  (!fuerBewertungsAnlaesse?.length || fuerBewertungsAnlaesse.includes(projekt.bewertungsAnlass)) &&
                  (!bewertungsAnlaesse?.length || bewertungsAnlaesse.includes(projekt.bewertungsAnlass)) &&
                  (!fuerKunden?.length || fuerKunden.includes(projekt.kunde) || fuerKunden.includes(projekt.bank)) &&
                  (!kunden?.length || kunden.includes(projekt.kunde) || kunden.includes(projekt.bank)) &&
                  (!fuerKundenArten?.length || fuerKundenArten.includes(kunde.kundenArt)) &&
                  (!kundenArten?.length || kundenArten.includes(kunde.kundenArt)) &&
                  (!fuerKundenGruppen?.length || fuerKundenGruppen.includes(kunde.kundenGruppe)) &&
                  (!fuerObjektArten?.length || gutachten?.some((gutachten) => fuerObjektArten.includes(gutachten.objektArt))),
              )
              .map(({ projekt }) => ({
                label: `${projekt.nummer} | ${projekt.bezeichnung} `,
                value: projekt.id,
              })),
          );
        }),
        this.$projekte.subscribe((projekte) => {
          const projekt = this.$projekt.getValue();
          if (!projekt || !projekte.map(({ value }) => value).includes(projekt)) this.$projekt.next(projekte[0]?.value);
        }),
        combineLatest([this.projekte.response$, this.$projekt, this.vorlage.result$, this.$formular]).subscribe(async ([result, id, vorlage, formular]) => {
          if (!result?.projekte || !vorlage?.vorlage) return;
          if (!id) return this.$gutachtens.next([]);
          this.projekt.payload$.next({ id });
          const row = result.projekte.find(({ projekt }) => projekt.id === id);
          if (!row) return;
          const entries =
            row.entries ||
            row.gutachten
              .filter(({ id, objekt }) => !!id && !!objekt)
              .map((gutachten) => ({
                gutachten,
                objekt: row.objekte?.find(({ id }) => id === gutachten.objekt),
                besichtigung: row.besichtigungen?.find(({ gutachten: id }) => id === gutachten.id),
              }));
          const { objektArten = [] } = formular || {};
          let { fuerObjektArten } = vorlage.vorlage;
          const gutachten = entries
            .filter(({ gutachten }) => (!fuerObjektArten?.length || fuerObjektArten.includes(gutachten.objektArt)) && (!objektArten?.length || objektArten.includes(gutachten.objektArt)))
            .map(({ gutachten, objekt }) => ({
              label: objekt.name,
              value: gutachten.id,
            }));
          this.$gutachtens.next(gutachten);
          if (!gutachten || !gutachten.map(({ value }) => value).includes(this.$gutachten.getValue())) this.$gutachten.next(gutachten[0]?.value);
        }),
        combineLatest([this.$alleFelder, this.$formular, this.$projekt, this.$gutachten])
          .pipe(debounceTime(500))
          .subscribe(async ([felder, formular, id, gutachten]) => {
            if (!felder?.length || !id || !gutachten) return;
            const projekt = this.projekte.response$.getValue()?.projekte.find(({ projekt }) => projekt.id === id);
            const obj = await this.generator.setObjekt(
              projekt,
              felder.filter(({ id }) => !formular?.felder.length || formular?.felder.includes(id)),
              gutachten,
            );
            this.$object.next(obj);
          }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setberichtsVorlagen(id: string) {
    /* << set-berichts-vorlagen    */
    /*    set-berichts-vorlagen >> */
  }

  public async setFelder(id: string) {
    /* << set-felder    */
    /*    set-felder >> */
  }

  public async setGutachten(id: string) {
    /* << set-gutachten    */
    /*    set-gutachten >> */
  }

  public async setProjekte(id: string) {
    /* << set-projekte    */
    /*    set-projekte >> */
  }

  public async setVorlage(id: string) {
    /* << set-vorlage    */
    /*    set-vorlage >> */
  }

  public async setVorlagen(id: string) {
    /* << set-vorlagen    */
    /*    set-vorlagen >> */
  }

  async finishedDeleteBerichtsVorlage(response?: IDeleteBerichtsVorlageResponse) {
    this.berichtsVorlageVisible = false;
  }

  async finishedPostBerichtsVorlage(response?: IPostBerichtsVorlageResponse) {
    this.berichtsVorlageVisible = false;
  }

  async finishedDeleteVorlage(response?: IDeleteVorlageResponse) {
    this.vorlageVisible = false;
    await this.navigateToVorlagen();
  }

  async finishedPostVorlage(response?: IPostVorlageResponse) {
    this.vorlageVisible = false;
    await this.navigateToVorlage({ id: (response as any).vorlage.id });
  }

  async finishedPostVorlageKopieren(response?: IPostVorlageKopierenResponse) {
    this.vorlageVisible = false;
    await this.navigateToVorlage({ id: response.vorlage });
  }

  public async navigateToVorlage(queryParams: Params = {}) {
    await this.router.navigate(this._vorlage.url, { queryParams });
  }
  public async navigateToVorlagen(queryParams: Params = {}) {
    await this.router.navigate(this._vorlagen.url, { queryParams });
  }

  async finishedPostAbschnitt(response?: IPostAbschnittResponse) {
    await this.openAbschnitt(undefined);
  }

  async finishedDeleteAbschnitt(response?: IDeleteAbschnittResponse) {
    await this.openAbschnitt(undefined);
  }

  async finishedPostSortiereAbschnitte(response?: IPostSortiereAbschnitteResponse) {}

  async openProjekt() {
    const id = this.$projekt.getValue();
    if (!id) return;
    const gutachten = this.$gutachten.getValue();
    await this.router.navigate(this._projekt.url, { queryParams: { id, gutachten, tab: 'gutachten', detail: 'gutachten' } });
  }

  back() {
    this.navigateToVorlagen();
  }

  public openBerichtsVorlage(berichtsVorlage?: IBerichtsVorlage) {
    this.$postBerichtsVorlage.next({ berichtsVorlage });
    this.$deleteBerichtsVorlage.next({ berichtsVorlage: berichtsVorlage?.id });
    this.berichtsVorlageVisible = true;
  }

  async neueBerichtsVorlage(datei: File) {
    const response = await this.postBerichtsVorlage.request({
      berichtsVorlage: {
        datei,
        name: datei.name,
        vorlagen: [this.id$.getValue()],
      },
    });
    this.openBerichtsVorlage(response.berichtsVorlage);
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const request = {
      abschnitte: this.$_abschnitte.getValue(),
    };
    if (request.abschnitte && $event) {
      this.$loading.next(true);
      moveItemInArray(request.abschnitte, $event.previousIndex, $event.currentIndex);
      request.abschnitte = request.abschnitte.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.submitPostSortiereAbschnitte(request);
      this.$loading.next(false);
    }
  }

  public async openAbschnitt(abschnitt?: IAbschnitt) {
    if (abschnitt && !abschnitt.id) {
      abschnitt.vorlage = this.id$.getValue();
      abschnitt.order = this.$_abschnitte.getValue().length + 1;
    }
    this.$postAbschnitt.next({ abschnitt });
    this.$deleteAbschnitt.next({ abschnitt: abschnitt?.id });
    this.view$.next(abschnitt ? 'abschnitt' : 'preview');
  }

  async download(datei: string) {
    await this.files.download(datei);
  }

  async run(vorlage: IBerichtsVorlage) {
    this.$loading.next(true);
    await this.generator.run(this.$object.getValue(), this.$_abschnitte.getValue(), vorlage);
    this.$loading.next(false);
  }

  async navigate(iterator: number) {
    const gutachten = this.$gutachten.getValue();
    const gutachtens = this.$gutachtens.getValue();
    if (!gutachten || !gutachtens) return;
    let gIndex = gutachtens.map((p) => p.value).indexOf(gutachten) + iterator;
    if (gIndex >= gutachtens.length || gIndex < 0) {
      const current = this.$projekt.getValue();
      const projekte = this.$projekte.getValue();
      if (!projekte || !current) return;
      let index = projekte.map((p) => p.value).indexOf(current) + iterator;
      if (index < 0) index = projekte.length - 1;
      if (index >= projekte.length) index = 0;
      this.$projekt.next(projekte[index].value);
    } else {
      if (gIndex < 0) gIndex = gutachtens.length - 1;
      if (gIndex >= gutachtens.length) gIndex = 0;
      this.$gutachten.next(gutachtens[gIndex].value);
    }
  }

  public async submitDeleteBerichtsVorlage(request?: IDeleteBerichtsVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteBerichtsVorlage.getValue();
    if (payload) {
      try {
        const response = await this.deleteBerichtsVorlage.request(payload);
        await this.finishedDeleteBerichtsVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostBerichtsVorlage(request?: IPostBerichtsVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postBerichtsVorlage.getValue();
    if (payload) {
      try {
        const response = await this.postBerichtsVorlage.request(payload);
        await this.finishedPostBerichtsVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteVorlage(request?: IDeleteVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteVorlage.getValue();
    if (payload) {
      try {
        const response = await this.deleteVorlage.request(payload);
        await this.finishedDeleteVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostVorlage(request?: IPostVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postVorlage.getValue();
    if (payload) {
      try {
        const response = await this.postVorlage.request(payload);
        await this.finishedPostVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostVorlageKopieren(request?: IPostVorlageKopierenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postVorlageKopieren.getValue();
    if (payload) {
      try {
        const response = await this.postVorlageKopieren.request(payload);
        await this.finishedPostVorlageKopieren(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostAbschnitt(request?: IPostAbschnittRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postAbschnitt.getValue();
    if (payload) {
      try {
        const response = await this.postAbschnitt.request(payload);
        await this.finishedPostAbschnitt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteAbschnitt(request?: IDeleteAbschnittRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteAbschnitt.getValue();
    if (payload) {
      try {
        const response = await this.deleteAbschnitt.request(payload);
        await this.finishedDeleteAbschnitt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostSortiereAbschnitte(request?: IPostSortiereAbschnitteRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postSortiereAbschnitte.getValue();
    if (payload) {
      try {
        const response = await this.postSortiereAbschnitte.request(payload);
        await this.finishedPostSortiereAbschnitte(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
