import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGutachtenResponseRow } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { GutachtenService } from '../service';

@Component({
  selector: 'fa-kt-gutachten-list',
  templateUrl: './gutachten-list.component.html',
  styleUrls: ['./gutachten-list.component.css'],
})
export class GutachtenListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public gutachten: GutachtenService) {}

  public set(gutachten: IGutachtenResponseRow) {}
}
