import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IGutachtenQueryFilter, IGutachtenQueryShapes, IGutachtenQuerySorter, IGutachtenRequest, IGutachtenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class GutachtenService extends ListQueryService<IGutachtenRequest, IGutachtenResponse, IGutachtenQueryShapes, IGutachtenQueryFilter, IGutachtenQuerySorter> {
  contentKey: keyof IGutachtenResponse = 'gutachten';
  route = 'gutachten/gutachten';
  override dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'dokumente/written-erforderliches-dokument',
    'dokumente/removed-erforderliches-dokument',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'dokumente/datei-gespeichert',
    'dokumente/dokument-hinterlegt',
    'einstellungen/kalkulation-hochgeladen',
    'felder/bewertet',
    'formulare/durchgang-gestartet',
    'gutachten/gutachten-gespeichert',
    'gutachten/gutachten-entfernt',
  ];
  override replacements: { [key: string]: string }[] = [
    {
      'gutachten.bewertungsAnlass': 'projekt.bewertungsAnlass',
    },
  ];

  static instance: GutachtenService;

  constructor() {
    super();
    if (!GutachtenService.instance) GutachtenService.instance = this;
    return GutachtenService.instance;
  }

  getObjektBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['objekt.bereiche']?.find((v) => v.value === value);
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objekt.bereiche'] as ISelection[]) : [])));
  }

  getBesichtigungBesichtigungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigung.besichtigungsStatus']?.find((v) => v.value === value);
  }

  get besichtigungBesichtigungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigung.besichtigungsStatus'] as ISelection[]) : [])));
  }

  getGutachtenBewertungsAnlass(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.bewertungsAnlass']?.find((v) => v.value === value);
  }

  get gutachtenBewertungsAnlass(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.bewertungsAnlass'] as ISelection[]) : [])));
  }

  getEintraegeFeld(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintraege.feld']?.find((v) => v.value === value);
  }

  get eintraegeFeld(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintraege.feld'] as ISelection[]) : [])));
  }

  getEintraegeGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintraege.gutachten']?.find((v) => v.value === value);
  }

  get eintraegeGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintraege.gutachten'] as ISelection[]) : [])));
  }

  getGutachtenBewertungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.bewertungsStatus']?.find((v) => v.value === value);
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.bewertungsStatus'] as ISelection[]) : [])));
  }

  getBerichteHerausgeber(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['berichte.herausgeber']?.find((v) => v.value === value);
  }

  get berichteHerausgeber(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['berichte.herausgeber'] as ISelection[]) : [])));
  }

  getBesichtigungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigung.mitarbeiter']?.find((v) => v.value === value);
  }

  get besichtigungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigung.mitarbeiter'] as ISelection[]) : [])));
  }

  getGutachtenObjekt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objekt']?.find((v) => v.value === value);
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objekt'] as ISelection[]) : [])));
  }

  getGutachtenObjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektArt']?.find((v) => v.value === value);
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektArt'] as ISelection[]) : [])));
  }

  getGutachtenObjektNutzung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektNutzung']?.find((v) => v.value === value);
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektNutzung'] as ISelection[]) : [])));
  }
}
