import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { addDays } from 'date-fns';

import { BasePageResolver } from 'pbc.angular';

import {
  IGutachtenRequest,
  IGutachtenResponse,
  IKundeRequest,
  IMitarbeiterRequest,
  IMitarbeiterResponse,
  IObjekteRequest,
  IObjekteResponse,
  IProjektRequest,
  IProjektResponse,
  ISollIstRequest,
  ISollIstResponse,
  IStandortsRequest,
  IStandortsResponse,
} from 'fa-kt.types';
import { SollIstService } from '../../../auswertungen';
import { BesichtigungenSelectService, PostTourCommandService } from '../../../besichtigungen';
import { KonstantesService } from '../../../einstellungen';
import { FelderService } from '../../../felder';
import { FormulareService } from '../../../formulare';
import { OrtKategoriesService, OrtsService } from '../../../geo';
import { GutachtenService, PostGutachtenCommandService } from '../../../gutachten';
import { PostKundeCommandService } from '../../../kunden';
import { ObjekteService } from '../../../objekte';
import { MitarbeiterService, StandortsService } from '../../../personen';
import { BerichtsVorlagenService, VorlagenService } from '../../../texte';
import { PostProjektCommandService, PostProjekteHochzaehlenCommandService } from '../../commands';
import { ProjekteSelectService, ProjektService } from '../../querys';

export interface IProjektPageResolverResult {
  gutachten?: IGutachtenResponse | void;
  projekt?: IProjektResponse | void;
  mitarbeiter?: IMitarbeiterResponse | void;
  standorts?: IStandortsResponse | void;
  sollIst?: ISollIstResponse | void;
  objekte?: IObjekteResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class ProjektPageResolver extends BasePageResolver<IProjektPageResolverResult> {
  constructor(
    private gutachten: GutachtenService,
    private objekte: ObjekteService,
    private projekt: ProjektService,
    private projekte: ProjekteSelectService,
    private besichtigungen: BesichtigungenSelectService,
    private formulare: FormulareService,
    private felder: FelderService,
    private sollIst: SollIstService,
    private postProjekt: PostProjektCommandService,
    private postProjekteHochzaehlen: PostProjekteHochzaehlenCommandService,
    private postGutachten: PostGutachtenCommandService,
    private mitarbeiter: MitarbeiterService,
    private standorte: StandortsService,
    private konstantes: KonstantesService,
    private vorlagen: VorlagenService,
    private berichtsVorlagen: BerichtsVorlagenService,
    public orts: OrtsService,
    public ortKategories: OrtKategoriesService,
    private postKunde: PostKundeCommandService,
    private postTour: PostTourCommandService,
  ) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IProjektPageResolverResult> {
    this.loading.resolving();
    const requests = {
      gutachten: {} as IGutachtenRequest,
      kunde: {} as IKundeRequest,
      objekte: {} as IObjekteRequest,
      projekt: {} as IProjektRequest,
      mitarbeiter: {} as IMitarbeiterRequest,
      standort: {} as IStandortsRequest,
      sollIst: {} as ISollIstRequest,
    };
    const id = route.queryParams['id'];
    const person = this.auth.$id.getValue()!;
    requests.sollIst.mitarbeiter = person;
    requests.sollIst.projekt = id;
    requests.projekt.id = id;
    requests.gutachten.projekt = id;
    const log = (error: any) => console.error(error);
    if (requests.projekt.id === 'new') {
      this.projekt.result$.next(undefined);
      const [hochgezaehlt, shapes, projekte, mitarbeiter, standorts] = await Promise.all([
        this.postProjekteHochzaehlen.request({}),
        this.postProjekt.prepare().catch(log),
        this.projekte.request({}).catch(log),
        this.mitarbeiter.request({}).catch(log),
        this.standorte.request({}).catch(log),
        this.formulare.request({}).catch(log),
        this.postGutachten.prepare().catch(log),
        this.vorlagen.request({}).catch(log),
        this.felder.request({}).catch(log),
        this.berichtsVorlagen.request({}).catch(log),
        this.projekte.request(this.projekte.payload$.getValue() || {}).catch(log),
        this.orts.request({}).catch(log),
        this.ortKategories.request({}).catch(log),
        this.postKunde.prepare().catch(log),
        this.postTour.prepare().catch(log),
      ]);
      this.loading.resolved();
      return {
        mitarbeiter,
        standorts,
        projekt: {
          projekt: {
            keineBesichtigungErforderlich: false,
            projektZaehler: hochgezaehlt?.zaehler || 0,
            bezeichnung: (hochgezaehlt?.zaehler || 0).toString(),
            projektStatus: shapes['projekt.projektStatus']?.[0]?.value,
            bewertungsAnlass: shapes['projekt.bewertungsAnlass']?.[0]?.value,
            beauftragung: new Date(),
            deadline: addDays(new Date(), this.konstantes.projekt_dauer),
            fortschritt: 0,
            aktiv: true,
          },
        } as unknown as IProjektResponse,
      };
    }
    /*    custom >> */
    const [gutachten, projekt, objekte, sollIst, mitarbeiter, standorts] = await Promise.all([
      this.gutachten.request(requests.gutachten).catch(log),
      this.projekt.request(requests.projekt).catch(log),
      this.objekte.request(requests.objekte).catch(log),
      this.sollIst.request(requests.sollIst).catch(log),
      this.mitarbeiter.request({}).catch(log),
      this.standorte.request({}).catch(log),
      this.formulare.request({}).catch(log),
      this.postGutachten.prepare().catch(log),
      this.postProjekt.prepare().catch(log),
      this.projekte.request({}).catch(log),
      this.besichtigungen.request({ projekt: id }).catch(log),
      this.vorlagen.request({}).catch(log),
      this.felder.request({}).catch(log),
      this.berichtsVorlagen.request({}).catch(log),
      this.projekte.request(this.projekte.payload$.getValue() || {}).catch(log),
      this.orts.request({}).catch(log),
      this.ortKategories.request({}).catch(log),
      this.postKunde.prepare().catch(log),
      this.postTour.prepare().catch(log),
    ]);
    this.loading.resolved();
    return {
      mitarbeiter,
      standorts,
      gutachten,
      projekt,
      sollIst,
      objekte,
    };
  }
}
