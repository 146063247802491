<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="gutachten.search$.next($event)" [search]="gutachten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-gutachten-filter [style.width]="'100%'"></fa-kt-gutachten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-gutachten-sorter [style.width]="'100%'"></fa-kt-gutachten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-gutachten-list [style.width]="'100%'" (select)="setGutachten($event)" [loading]="loading$ | async"></fa-kt-gutachten-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="objekte.search$.next($event)" [search]="objekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-objekte-filter [style.width]="'100%'"></fa-kt-objekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-objekte-sorter [style.width]="'100%'"></fa-kt-objekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-objekte-list [style.width]="'100%'" (select)="setObjekte($event)" [loading]="loading$ | async"></fa-kt-objekte-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekt.search$.next($event)" [search]="projekt.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekt-filter [style.width]="'100%'"></fa-kt-projekt-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekt-sorter [style.width]="'100%'"></fa-kt-projekt-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekt-list [style.width]="'100%'" (select)="setProjekt($event)" [loading]="loading$ | async"></fa-kt-projekt-list>


  <fa-kt-post-objekt [style.width]="'100%'" [value]="$postObjekt | async" (finished)="finishedPostObjekt($event)"></fa-kt-post-objekt>

  <fa-kt-delete-objekt [style.width]="'100%'" [value]="$deleteObjekt | async" (finished)="finishedDeleteObjekt($event)"></fa-kt-delete-objekt>

  <fa-kt-post-durchgang [style.width]="'100%'" [value]="$postDurchgang | async" (finished)="finishedPostDurchgang($event)"></fa-kt-post-durchgang>

  <fa-kt-post-gutachten [style.width]="'100%'" [value]="$postGutachten | async" (finished)="finishedPostGutachten($event)"></fa-kt-post-gutachten>

  <fa-kt-delete-gutachten [style.width]="'100%'" [value]="$deleteGutachten | async" (finished)="finishedDeleteGutachten($event)"></fa-kt-delete-gutachten>


  <fa-kt-berichte [style.width]="'100%'" ></fa-kt-berichte>

  <fa-kt-dokumenten-anforderungen [style.width]="'100%'" ></fa-kt-dokumenten-anforderungen>

  <fa-kt-kommunikation [style.width]="'100%'" ></fa-kt-kommunikation>

  <fa-kt-sicherheitscheck [style.width]="'100%'" ></fa-kt-sicherheitscheck>

  <fa-kt-eintraege [style.width]="'100%'" ></fa-kt-eintraege>

  <fa-kt-objekte [style.width]="'100%'" ></fa-kt-objekte>

  <fa-kt-kalkulationen [style.width]="'100%'" ></fa-kt-kalkulationen>


  <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="objekte" type="dashed"></fa-kt-navigation>

  <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="umkreissuche" type="dashed"></fa-kt-navigation>

  <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="durchgang" type="dashed"></fa-kt-navigation>

-->
<!--- << custom    -->
<ng-container [ngSwitch]="!($postGutachten | async)">
  <ng-container *ngSwitchCase="true">
    <nz-row>
      <nz-col nzSpan="20">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="gutachten.search$.next($event)" [search]="gutachten.search$ | async"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="1"> </nz-col>
      <nz-col nzSpan="3">
        <fa-kt-saved-gutachten-filters style="width: 100%"></fa-kt-saved-gutachten-filters>
      </nz-col>
      <nz-col nzSpan="24">
        <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
          <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
            <fa-kt-gutachten-filter [style.width]="'100%'"></fa-kt-gutachten-filter>
          </nz-col>
          <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
            <fa-kt-gutachten-sorter [style.width]="'100%'"></fa-kt-gutachten-sorter>
          </nz-col>
        </nz-row>
      </nz-col>
      <nz-col nzSpan="24" style="padding: 16px">
        <button type="button" nz-button nzType="primary" [nzBlock]="true" (click)="createGutachten()">
          <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
          Gutachten hinzufügen
        </button>
      </nz-col>
      <nz-col nzSpan="24">
        <nz-list>
          <nz-list-item *ngFor="let row of (gutachten.result$ | async)?.gutachten" (click)="setGutachten(row.gutachten.id)">
            <a>
              <pbc-color-icon [color]="getColorByFortschritt(row.gutachten?.fortschritt)" nz-tooltip [nzTooltipTitle]="Fortschritt"></pbc-color-icon>
              <ng-template #Fortschritt>
                <span> {{ row.gutachten?.fortschritt || 0 | number: '0.0-2' }} % Fortschritt </span>
              </ng-template>

              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography>
                {{ row.gutachten?.objekt | type: ($objekte | async) }}
              </span>
              <nz-divider nzType="vertical"></nz-divider>
              <span [style.color]="row.gutachten?.bewertungsStatus | type: (gutachten.gutachtenBewertungsStatus | async) : 'color'">
                <i nz-icon [nzType]="row.gutachten?.bewertungsStatus | type: (gutachten.gutachtenBewertungsStatus | async) : 'icon'" nzTheme="outline"></i>
                {{ row.gutachten?.bewertungsStatus | type: (gutachten.gutachtenBewertungsStatus | async) }}
              </span>
              <ng-container *ngIf="row.gutachten.abgabeFinal">
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nz-tooltip nzTooltipTitle="Finale Abgabe"
                  ><span nz-icon nzType="flag" nzTheme="outline"></span>
                  {{ row.gutachten.abgabeFinal | date: 'dd.MM.yyyy' }}
                </span>
              </ng-container>
              <ng-container *ngIf="row.gutachten.tourenplanung || row.besichtigung?.von || row.gutachten?.datum_besichtigung">
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nz-tooltip nzTooltipTitle="Besichtigung" (click)="navigateToTour(row.besichtigung?.tour)">
                  <span nz-typography nzType="secondary" *ngIf="row.gutachten.tourenplanung"> 🚲️ </span>
                  <mark *ngIf="row.besichtigung?.von || row.gutachten?.datum_besichtigung" style="margin-left: 8px">
                    {{ row.besichtigung?.von ? (row.besichtigung?.von | date: 'dd.MM.yyyy') : (row.gutachten?.datum_besichtigung | date: 'dd.MM.yyyy') }}
                  </mark>
                </span>
              </ng-container>
              <ng-container *ngIf="row.gutachten?.kommentar">
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nzType="secondary" class="text-lg" nzEllipsis>{{ row.gutachten?.kommentar }}</span>
              </ng-container>
            </a>
          </nz-list-item>
        </nz-list>
      </nz-col>
      <nz-col nzSpan="24" style="padding: 16px">
        <pbc-atlas-map height="300px" width="100%" [coordinateArray]="$coordinateArray | async" mode="addresses" (clicked)="setGutachten($event.id)"></pbc-atlas-map>
      </nz-col>
    </nz-row>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <nz-row>
      <nz-col nzSpan="24">
        <nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" nzBackIcon="close-circle" (nzBack)="close()">
          <ng-template #PageTitle>
            {{ ($postGutachten | async)?.gutachten?.objekt | type: ($objekte | async) }}
          </ng-template>
          <ng-template #PageSubtitle>
            {{ ($postGutachten | async)?.gutachten?.bewertungsStatus | type: (postGutachten.gutachtenBewertungsStatus | async) }}
          </ng-template>
          <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
          <nz-page-header-extra>
            <nz-space>
              <div *nzSpaceItem>
                <fa-kt-delete-gutachten [style.width]="'100%'" [value]="$deleteGutachten | async" (finished)="finishedDeleteGutachten()"></fa-kt-delete-gutachten>
              </div>
            </nz-space>
          </nz-page-header-extra>
          <nz-page-header-content>
            <ul nz-menu nzMode="horizontal" nzSize="large" nzCentered>
              <li nz-menu-item [nzSelected]="($detail | async) === 'gutachten'">
                <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ detail: 'gutachten' }" queryParamsHandling="merge" [replaceUrl]="true">
                  <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Gutachten
                </a>
              </li>
              <li nz-menu-item [nzDisabled]="!($gutachten | async) || ($gutachten | async) === 'new'" [nzSelected]="($detail | async) === 'dokumenten-anforderungen'">
                <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ detail: 'dokumenten-anforderungen' }" queryParamsHandling="merge" [replaceUrl]="true">
                  <i nz-icon nzType="file" nzTheme="outline"></i>
                  {{ ($fortschritt | async) || 0 | number: '0.0-2' }}% von {{ ($row | async)?.erforderlicheDokumente?.length || 0 | number: '0.0-2' }} Anforderungen
                  <span *ngIf="dokumente.length > 0" style="color: green" nz-icon nzType="edit" nzTheme="outline"></span>
                </a>
              </li>
              <li nz-menu-item [nzDisabled]="!($gutachten | async) || ($gutachten | async) === 'new'" [nzSelected]="($detail | async) === 'eintraege'">
                <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ detail: 'eintraege' }" queryParamsHandling="merge" [replaceUrl]="true">
                  <i nz-icon nzType="check-square" nzTheme="outline"></i>
                  {{ (($row | async)?.eintraege.length || 0) + eintraege.length }} Bewertungen
                  <span *ngIf="eintraege.length > 0" style="color: green" nz-icon nzType="edit" nzTheme="outline"></span>
                </a>
              </li>
            </ul>
          </nz-page-header-content>
        </nz-page-header>
        <nz-content>
          <nz-row>
            <ng-container [ngSwitch]="$detail | async">
              <ng-container *ngSwitchCase="'gutachten'">
                <nz-col nzSpan="24">
                  <fa-kt-post-gutachten
                    [style.width]="'100%'"
                    [value]="$postGutachten | async"
                    [gutachten]="$patchGutachten | async"
                    [besichtigung]="$besichtigung | async"
                    (finished)="finishedPostGutachten($event)"
                    (sicherheitscheck)="sicherheitscheck($event)"
                    (selected)="openObjekte($event)"
                  ></fa-kt-post-gutachten>
                  <pbc-meta [value]="($postGutachten | async)?.gutachten"></pbc-meta>
                </nz-col>
              </ng-container>
              <nz-col nzSpan="24" *ngSwitchCase="'dokumenten-anforderungen'">
                <fa-kt-dokumenten-anforderungen
                  [dokumentenAnforderungen]="($row | async)?.erforderlicheDokumente"
                  [projekt]="project"
                  [kunde]="kunde"
                  [gutachten]="$gutachten | async"
                  [disabled]="!($gutachten | async)"
                  [(gespeicherte)]="dokumente"
                  (gespeicherteChange)="setFortschritt($event); valid.emit($event.length + eintraege.length === 0)"
                  [style.width]="'100%'"
                ></fa-kt-dokumenten-anforderungen>
              </nz-col>
              <nz-col nzSpan="24" *ngSwitchCase="'eintraege'">
                <fa-kt-kalkulationen [style.width]="'100%'" [gutachten]="$gutachten | async"></fa-kt-kalkulationen>
                <fa-kt-eintraege
                  [(eintraege)]="eintraege"
                  (eintraegeChange)="valid.emit($event.length + dokumente.length === 0)"
                  [gutachten]="$gutachten | async"
                  [disabled]="!($gutachten | async)"
                  [style.width]="'100%'"
                ></fa-kt-eintraege>
              </nz-col>
            </ng-container>
          </nz-row>
        </nz-content>
      </nz-col>
      <nz-col nzSpan="24" style="padding: 16px">
        <pbc-atlas-map height="300px" width="100%" [coordinates]="$coordinates | async" (clicked)="openAddress()"></pbc-atlas-map>
      </nz-col>
    </nz-row>
  </ng-container>
</ng-container>

<!---    custom >> -->
