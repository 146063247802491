import { Component } from '@angular/core';
import { GutachtenService } from '../service';

@Component({
  selector: 'fa-kt-saved-gutachten-filters',
  templateUrl: './saved-gutachten-filters.component.html',
  styleUrls: ['./saved-gutachten-filters.component.css'],
})
export class SavedGutachtenFiltersComponent {
  constructor(public gutachten: GutachtenService) {}
}
